<!--  -->
<template>
  <div class="page">
    <img class="img" src="../assets/img/img-404.png" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  components: {},

  computed: {},

  methods: {},

  created() {},
};
</script>
<style lang='less' scoped>
.page {
  text-align: center;
}
.img {
  margin-top: 100px;
}
</style>